import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "full-card"
    }}>{`Full Card`}</h2>
    <div className="row mb2">
  <div className="column xlarge-6 medium-12 small-12">
    <div className="card card-hover">
      <div className="card-header">
        <h4>Card header</h4>
      </div>
      <div className="card-img">
        <img src="/assets/drone.JPG" alt="Drone picture" />
      </div>
      <div className="card-footer">
        <h4>Card footer</h4>
      </div>
    </div>
  </div>
  <div className="column xlarge-6 medium-12 small-12"></div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="card card-hover">
  <div class="card-header">
    <h4>Card header</h4>
  </div>
  <div class="card-img">
    <img src="./source.jpg" alt="" />
  </div>
  <div class="card-footer">
    <h4>Card footer</h4>
  </div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "headless-card"
    }}>{`Headless Card`}</h2>
    <div className="row mb2">
  <div className="column xlarge-6 medium-12 small-12">
    <div className="card card-no-header card-hover">
      <div className="card-img">
        <img src="/assets/drone1.JPG" alt="Drone picture" />
      </div>
      <div className="card-footer">
        <h4>Card footer</h4>
      </div>
    </div>
  </div>
  <div className="column xlarge-6 medium-12 small-12"></div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="card card-no-header card-hover">
  <div class="card-img">
    <img src="./source.jpg" alt="Drone picture" />
  </div>
  <div class="card-footer">
    <h4>Card footer</h4>
  </div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "footerless-card"
    }}>{`Footerless Card`}</h2>
    <div className="row mb2">
  <div className="column xlarge-6 medium-12 small-12">
    <div className="card card-no-footer card-hover">
      <div className="card-header">
        <h4>Card header</h4>
      </div>
      <div className="card-img">
        <img src="/assets/drone2.jpg" alt="Drone picture" />
      </div>
    </div>
  </div>
  <div className="column xlarge-6 medium-12 small-12"></div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="card card-no-footer card-hover">
  <div class="card-header">
    <h4>Card header</h4>
  </div>
  <div class="card-img">
    <img src="source.jpg" alt="Drone picture" />
  </div>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      